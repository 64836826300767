import React from "react";
import "../css/footer.css";

const Footer = () => (
  <footer className="app-footer">
    <p>david@davidlongwith.com</p>
  </footer>
);

export default Footer;
